import dayjs from 'dayjs';

export interface DailyDriverValue {
  forecast: number;
  fcDirty?: boolean;
  fcLoaded?: boolean;
  actual: number;
  aDirty?: boolean;
  aLoaded?: boolean;
  closed?: boolean;
}

export type SectionType = 'ALLMAIN' | 'HOTEL' | 'FRONTOFFICE' | 'CLUBLOUNGE' | 'ROOMSERVICE' | 'CANDB' | 'RESTAURANT' | 'BAR';

export interface DailyDriverLine {
  sectionType: SectionType;
  sectionIndex: number;
  sectionLabel: string;
  metricLabel: string;
  dataKey: string;
  default?: any;
  dataLabel: string;
  isInteger?: boolean;
  days: DailyDriverValue[];
  fTotal?: number;
  fTotalPartial?: boolean;
  aTotal?: number;
  tabIndex?: number;
  aTotalPartial?: boolean;
  fWeekTotal?: number;
  aWeekTotal?: number;
  WOTTotal?: number;
  WOTTotalString?: string;
  allowForecastWrite?: boolean;
  allowActualWrite?: boolean;
  sectionClass?: string;
  exportExclude?: boolean;
  day?;
  average?: boolean;
  closedDays?: Set<number>;
  closedMonths?: Set<number>;
  max?: number;
  min?: number;
}

export function makeISODateOnly(d: Date) {
  return dayjs(d).format('YYYY-MM-DD');
}
