import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, interval, Observable, Subscription } from 'rxjs';
import { DailyDriverLine, SectionType } from 'src/commontypes/drivers';
import { ContextService } from 'src/services/context.service';
import { DriverDataService } from 'src/services/driver-data.service';
import { SentryService } from 'src/services/sentry.service';
import * as dayjs from 'dayjs';

import { AuthService } from 'src/services/auth.service';
import { catchError, distinctUntilKeyChanged, filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { DepartmentName, Right, Scope, CalendarLength, createDeptExistsFn } from 'src/commontypes/util';
import { LocalService } from 'src/services/local.service';
import { LoggingService } from 'src/services/logging.service';
import { MessageService } from 'primeng/api';
import { XLSService } from 'src/services/xls.service';
import { UIService } from 'src/services/ui.service';
import { environment } from 'src/environments/environment';
import { cloneDeep } from '@apollo/client/utilities';

interface IDailyDriverGroup {
  sectionType: SectionType;
  sectionLabel: string;
  sectionIndex: number;
  lines: DailyDriverLine[];
}

interface IQueueEntry {
  sectionType: SectionType;
  sectionIndex: number;
  day: number;
  lastChange: number;
  forecast: boolean;
  actual: boolean;
  label: string;
  newSectionValue: Record<string, { changed?: boolean; value: number }>;
}

const days = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};
const months = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
};
enum ColCode {
  FORECAST = 1,
  ACTUAL = 2,
  FORECAST_VS_ACTUAL = 3,
}

@Component({
  selector: 'app-dailydrivers',
  styleUrls: ['./dailydrivers.component.scss'],
  templateUrl: './dailydrivers.component.html',
})
export class DailyDriversComponent implements OnInit, OnDestroy {
  befName = environment.names.bef;
  readonly UPDATE_INTERVAL_MS = 250;
  activeUpdates = 0;
  hotelWatch$: Subscription;
  updateWatch$: Subscription;
  updateShow$: Subscription;
  weekStart: Date;
  activeMonthStart = dayjs();
  activeMonthIndexStart = 0;
  activeMonthIndexEnd = 0;
  firstDay: dayjs.Dayjs;
  displayOffset = 0;
  dateRange: Date[] = [];
  canEditDayFC: boolean[] = [];
  canEditDayAct: boolean[] = [];

  displayRange: number[] = [];
  isLoading = true;
  data: IDailyDriverGroup[] = [];
  importingData = false;
  showColCodes: { code: ColCode; label: string }[] = [];
  showCols: ColCode;
  loadQueue: { sectionType: SectionType; sectionIndex; default?: Record<string, any> }[] = [];
  hasActualRead = false;
  hasForecastRead = false;
  hasAdminRead = false;
  hasSuperAdmin = false;
  allowSuperWrite = false;
  showTime: CalendarLength = null;
  updateQueue: IQueueEntry[] = [];
  requestRefresh = false;
  showingUpdate = false;
  showTimes = [
    { code: CalendarLength.MON_WEEK, label: 'Week (Mon - Sun)' },
    { code: CalendarLength.SUN_WEEK, label: 'Week (Sun - Sat)' },
  ];
  yearRange = `${dayjs().subtract(1, 'years').year()}:${dayjs().add(3, 'years').year()}`;

  @ViewChild('uploadarea') uploadControl;

  constructor(
    private contextService: ContextService,
    private driverData: DriverDataService,
    private authService: AuthService,
    private sentryService: SentryService,
    private local: LocalService,
    private log: LoggingService,
    private messageService: MessageService,
    private xls: XLSService,
    private ui: UIService
  ) { }

  ngOnInit() {
    this.updateShow$ = this.messageService.messageObserver.subscribe((message) => {
      if (!Array.isArray(message)) {
        if (message?.id === 'update_message') {
          this.showingUpdate = true;
        }
      } else if (message.some((m) => m.id === 'update_message')) {
        this.showingUpdate = true;
      }
    });
    this.local
      .isReady()
      .pipe(
        filter((ready) => ready),
        first()
      )
      .subscribe(() => {
        const calendarLengths = this.local.getRecentlyUsedList('calendarLength', 4);
        if (calendarLengths && calendarLengths.length > 0) {
          const item = calendarLengths.find((entry) => this.showTimes.some((time) => time.code === entry));
          if (item) {
            this.showTime = item;
          }
        }
        // check if we need to initialize the showTime
        if (this.showTime === null) {
          this.showTime = CalendarLength.MON_WEEK;
          // only change the saved item if there wasn't one previously set
          if (!calendarLengths || calendarLengths.length === 0) {
            this.local.addRecentlyUsedList('calendarLength', this.showTime, (d) => d === CalendarLength.MON_WEEK);
          }
        }

        const recentDay = this.local.getRecentlyUsedList('currentDay');
        const date = recentDay && recentDay.length > 0 ? new Date(recentDay[0]) : new Date();
        if (!recentDay || recentDay.length === 0) {
          this.local.addRecentlyUsedList('currentDay', date.toISOString(), (d) => dayjs(d).isSame(date, 'day'));
        }

        if (this.showTime === CalendarLength.MON_WEEK) {
          this.weekStart = dayjs(date).startOf('week').add(1, 'day').toDate();
        } else {
          this.weekStart = dayjs(date).startOf('week').toDate();
        }

        this.hotelWatch$ = this.contextService
          .getCurrentHotelWithDeptConfig$()
          .pipe(
            filter((hotel) => !!hotel?.id),
            distinctUntilKeyChanged('id')
          )
          .subscribe(
            (hotel) => {
              this.activeUpdates = 0;
              this.isLoading = true;
              forkJoin({
                hasActual: this.authService
                  .hasAnyRoles([
                    [Scope.SCHEDULE_ACTUAL, Right.READ],
                    [Scope.SCHEDULE_ADMIN, Right.READ],
                    [Scope.REGION_ADMIN, Right.READ],
                  ])
                  .pipe(first()),
                hasSuperAdmin: this.authService.hasAnyRoles([[Scope.REGION_ADMIN, Right.WRITE]]).pipe(first()),
                hasForecast: this.authService
                  .hasAnyRoles([
                    [Scope.SCHEDULE_FORECAST, Right.READ],
                    [Scope.SCHEDULE_ADMIN, Right.READ],
                    [Scope.REGION_ADMIN, Right.READ],
                  ])
                  .pipe(first()),
                hasAdmin: this.hasAdminRight(Right.READ).pipe(first()),
              })
                .pipe(first())
                .subscribe(
                  ({ hasActual, hasForecast, hasAdmin, hasSuperAdmin }) => {
                    this.hasSuperAdmin = hasSuperAdmin;
                    this.allowSuperWrite = false;
                    this.hasActualRead = hasAdmin || hasActual;
                    this.hasForecastRead = hasAdmin || hasForecast;
                    this.hasAdminRead = hasAdmin;
                    if (this.hasActualRead && this.hasForecastRead) {
                      this.showColCodes = [
                        { code: ColCode.FORECAST, label: 'Forecast' },
                        { code: ColCode.ACTUAL, label: 'Actual' },
                        { code: ColCode.FORECAST_VS_ACTUAL, label: 'Forecast vs Actual' },
                      ];
                    } else if (this.hasActualRead) {
                      this.showColCodes = [{ code: ColCode.ACTUAL, label: 'Actual' }];
                    } else {
                      this.showColCodes = [{ code: ColCode.FORECAST, label: 'Forecast' }];
                    }
                    this.showCols = this.showColCodes[0].code;
                    const departmentExists = createDeptExistsFn(hotel.departmentConfig);
                    this.createLinesForHotel({ ...hotel, departmentExists })
                      .pipe(first())
                      .subscribe(
                        (data) => {
                          this.data = data;
                          this.firstDay = dayjs('1900-01-01'); //clear current data by resetting context
                          this.dateChange();
                          this.isLoading = false;
                        },
                        (linesError) => {
                          this.sentryService.showAndSendError(linesError, 'Internal error - drivers', 'Unable to display drivers.');
                          this.isLoading = false;
                        }
                      );
                  },
                  (authErr) => {
                    this.sentryService.showAndSendError(authErr, 'Authentication error - drivers', 'Unable to determine user rights.');
                    this.isLoading = false;
                  }
                );
            },
            (hotelError) => {
              this.sentryService.showAndSendError(hotelError, 'Internal error - drivers', 'Unable to retrieve hotel data drivers.');
              this.isLoading = false;
            }
          );
        this.updateWatch$ = interval(this.UPDATE_INTERVAL_MS).subscribe((time) => this.processUpdates(false));
      });
  }

  ngOnDestroy(): void {
    if (this.updateShow$) {
      this.updateShow$.unsubscribe();
      this.updateShow$ = undefined;
    }
    if (this.hotelWatch$) {
      this.hotelWatch$.unsubscribe();
      this.hotelWatch$ = undefined;
    }
    if (this.updateWatch$) {
      this.updateWatch$.unsubscribe();
      this.updateWatch$ = undefined;
    }
    this.processUpdates(true);
  }

  setToday() {
    this.weekStart = new Date();
    this.log.debug('setToday - date change');
    this.dateChange();
  }

  dateChange(): void {
    this.log.debug('date change');
    this.local.addRecentlyUsedList('currentDay', this.weekStart.toISOString(), (d) => dayjs(d).isSame(this.weekStart, 'd'));
    this.processUpdates(true); //save everything outstanding right now
    if (this.showTime === CalendarLength.MON_WEEK) {
      this.weekStart = dayjs(this.weekStart).startOf('week').add(1, 'day').toDate();
    } else {
      this.weekStart = dayjs(this.weekStart).startOf('week').toDate();
    }
    const cd = dayjs(this.weekStart);
    this.activeMonthStart = this.contextService.jsDateToUTC(cd.endOf('w').startOf('M').toDate());
    //we need to load from a day before the week at the start of the month
    const firstday = cd.endOf('w').startOf('month').startOf('week').subtract(1, 'day');
    // calculate the weeks which overlap the current month and then load an extra three to leave room for
    // Sun - Sat and Mon - Sun weeks
    const maxDays = cd.endOf('week').endOf('month').diff(firstday, 'days') + 3;
    this.displayOffset = cd.diff(firstday, 'days');
    //choose the active indexes

    this.activeMonthIndexStart = this.activeMonthStart.diff(firstday, 'days');
    let activeMonthEnd = this.contextService.jsDateToUTC(cd.endOf('w').endOf('M').startOf('d').toDate());
    this.activeMonthIndexEnd = activeMonthEnd.diff(firstday, 'days');

    //if the startdate has changed run the data reload
    if (!firstday.isSame(this.firstDay)) {
      //reset the date date range
      this.dateRange = Array(maxDays)
        .fill('')
        .map((_, i) => {
          return firstday.add(i, 'days').toDate();
        });
      this.firstDay = firstday;
      this.loadWOTNumbers();
      this.loadDaysForEachLine();
    }
    //create the display range
    this.displayRange = Array(7)
      .fill('')
      .map((_, i) => i + this.displayOffset);
    this.canEditDayFC = this.dateRange.map((d) => dayjs(d).isAfter(dayjs())); //future forecats only
    this.canEditDayAct = this.dateRange.map((d) => dayjs(d).endOf('month').add(8, 'day').isAfter(dayjs()) && dayjs(d).isBefore(dayjs())); //actuals for the past 8 days only
  }

  setCal(diff: number) {
    this.weekStart = new Date(+this.weekStart + diff * 24 * 60 * 60 * 1000);
    this.log.debug('setCal - date change');
    this.dateChange();
    this.data.forEach((group) => {
      group.lines.forEach((l) => {
        this.calculateActualLineTotal(l);
        this.calculateForecastLineTotal(l);
      });
      this.calculateExpectedOccupancy();
    });
  }

  createLinesForHotel(hotel: { bars; restaurants; departmentExists; numberOfRooms; numberOfClubRooms?}) {
    //create lines for each bar and outlet
    this.log.debug('creating the mad forkJoin');
    return forkJoin({
      hasForecastWriteRight: this.authService.hasAllRoles([[Scope.SCHEDULE_FORECAST, Right.WRITE]]).pipe(first()),
      hasActualWriteRight: this.authService.hasAllRoles([[Scope.SCHEDULE_ACTUAL, Right.WRITE]]).pipe(first()),
      hasAdminWrite: this.hasAdminRight(Right.WRITE).pipe(first()),
      hasHotelReadDept: this.authService
        .hasAnyDepartment([
          { name: DepartmentName.FRONT_OFFICE },
          { name: DepartmentName.UNIFORMED_SERVICES },
          { name: DepartmentName.CLUB_LOUNGE },
          { name: DepartmentName.HOUSEKEEPING },
          { name: DepartmentName.LAUNDRY },
          { name: DepartmentName.MINI_BARS },
          { name: DepartmentName.ENGINEERING },
          { name: DepartmentName.SWITCHBOARD },
          { name: DepartmentName.RESERVATIONS },
        ])
        .pipe(first()),
      hasRoomsWriteDept: this.authService
        .hasAnyDepartment([{ name: DepartmentName.RESERVATIONS }, { name: DepartmentName.FRONT_OFFICE }])
        .pipe(first()),
      hasFrontOfficeWriteDept: this.authService
        .hasAnyDepartment([{ name: DepartmentName.RESERVATIONS }, { name: DepartmentName.FRONT_OFFICE }])
        .pipe(first()),
      hasClubLoungeReadDept: this.authService
        .hasAnyDepartment([
          { name: DepartmentName.FRONT_OFFICE },
          { name: DepartmentName.CLUB_LOUNGE },
          { name: DepartmentName.CLUB_LOUNGE, subDeptName: DepartmentName.KITCHEN },
          { name: DepartmentName.CLUB_LOUNGE, subDeptName: DepartmentName.STEWARDING },
        ])
        .pipe(first()),
      hasClubLoungeWriteDept: this.authService.hasDepartment({ name: DepartmentName.FRONT_OFFICE }).pipe(first()),
      hasRoomServiceReadDept: this.authService
        .hasAnyDepartment([{ name: DepartmentName.ROOM_SERVICE }, { name: DepartmentName.KITCHEN }, { name: DepartmentName.STEWARDING }])
        .pipe(first()),
      hasRoomServiceWriteDept: this.authService.hasDepartment({ name: DepartmentName.ROOM_SERVICE }).pipe(first()),
      hasConferenceReadDept: this.authService
        .hasAnyDepartment([
          { name: DepartmentName.C_AND_E },
          { name: DepartmentName.C_AND_E, subDeptName: DepartmentName.KITCHEN },
          { name: DepartmentName.C_AND_E, subDeptName: DepartmentName.STEWARDING },
          { name: DepartmentName.KITCHEN },
          { name: DepartmentName.STEWARDING },
        ])
        .pipe(first()),
      hasConferenceWriteDept: this.authService.hasDepartment({ name: DepartmentName.C_AND_E }).pipe(first()),
      bars: this.authService.getOutlets('BAR').pipe(
        first(),
        map(({ all, main, kitchens, stewarding }) => ({
          useAll: all,
          main,
          all: all ? [] : Array.from(new Set([...main, ...kitchens, ...stewarding])),
        }))
      ),
      restaurants: this.authService.getOutlets('RESTAURANT').pipe(
        first(),
        map(({ all, main, kitchens, stewarding }) => ({
          useAll: all,
          main,
          all: all ? [] : Array.from(new Set([...main, ...kitchens, ...stewarding])),
        }))
      ),
      hasOutletReadDept: this.authService
        .hasAnyDepartment([{ name: DepartmentName.KITCHEN }, { name: DepartmentName.STEWARDING }])
        .pipe(first()),
    }).pipe(
      map(
        ({
          hasForecastWriteRight,
          hasActualWriteRight,
          hasAdminWrite,
          hasHotelReadDept,
          hasRoomsWriteDept,
          hasFrontOfficeWriteDept,
          hasClubLoungeReadDept,
          hasClubLoungeWriteDept,
          hasRoomServiceReadDept,
          hasRoomServiceWriteDept,
          hasConferenceReadDept,
          hasConferenceWriteDept,
          bars: { useAll: useAllBars, all: allBars, main: mainBars },
          restaurants: { useAll: useAllRests, all: allRests, main: mainRests },
          hasOutletReadDept,
        }) => {
          if (!this.hasForecastRead && !this.hasActualRead && !hasActualWriteRight && !hasForecastWriteRight && !hasAdminWrite) {
            return [];
          }
          const hotelRead = hasHotelReadDept || this.hasAdminRead;
          const clubLoungeRead = hasClubLoungeReadDept || hasClubLoungeWriteDept || this.hasAdminRead;
          const roomsServiceRead = hasRoomServiceReadDept || hasRoomServiceWriteDept || this.hasAdminRead;
          const conferenceRead = hasConferenceReadDept || this.hasAdminRead;
          const hotelGroups = hotelRead
            ? this.createHotelLines(
              hasAdminWrite || (hasRoomsWriteDept && hasForecastWriteRight),
              hasAdminWrite || (hasRoomsWriteDept && hasActualWriteRight),
              hotel.numberOfRooms
            )
            : [];
          const frontOfficeGroups =
            hotelRead && hotel.departmentExists('FRONT_OFFICE')
              ? this.createFrontOfficeLines(
                hasAdminWrite || (hasFrontOfficeWriteDept && hasForecastWriteRight),
                hasAdminWrite || (hasFrontOfficeWriteDept && hasActualWriteRight),
                hotel.numberOfRooms
              )
              : [];
          const clubLoungeGroups =
            clubLoungeRead && hotel.departmentExists('CLUB_LOUNGE')
              ? this.createClubLoungeLines(
                hasAdminWrite || (hasClubLoungeWriteDept && hasForecastWriteRight),
                hasAdminWrite || (hasClubLoungeWriteDept && hasActualWriteRight),
                hotel.numberOfClubRooms
              )
              : [];
          // the outlet and bars arrays are readonly so make a shallow copy so that we can (destructively) sort them
          const outletGroups = [...(hotel.restaurants || [])]
            .sort((a, b) => (a.index < b.index ? -1 : 1))
            .reduce((acc, out) => {
              if (this.hasAdminRead || useAllRests || allRests.includes(out.index) || hasOutletReadDept) {
                return acc.concat(
                  this.createRestaurantLines(
                    out,
                    hasAdminWrite || ((useAllRests || mainRests.includes(out.index)) && hasForecastWriteRight),
                    hasAdminWrite || ((useAllRests || mainRests.includes(out.index)) && hasActualWriteRight) // only have write access if you have access to the main restaurant
                  )
                );
              }
              return acc;
            }, [] as IDailyDriverGroup[]);
          const barGroups = [...(hotel.bars || [])]
            .sort((a, b) => (a.index < b.index ? -1 : 1))
            .reduce((acc, bar) => {
              if (this.hasAdminRead || useAllBars || allBars.includes(bar.index) || hasOutletReadDept) {
                return acc.concat(
                  this.createBarLines(
                    bar,
                    hasAdminWrite || ((useAllBars || mainBars.includes(bar.index)) && hasForecastWriteRight),
                    hasAdminWrite || ((useAllBars || mainBars.includes(bar.index)) && hasActualWriteRight) // only have write access if you have access to the main bar
                  )
                );
              }
              return acc;
            }, [] as IDailyDriverGroup[]);
          const roomsServiceGroups =
            roomsServiceRead && hotel.departmentExists('ROOM_SERVICE')
              ? this.createRoomServiceLines(
                hasAdminWrite || (hasRoomServiceWriteDept && hasForecastWriteRight),
                hasAdminWrite || (hasRoomServiceWriteDept && hasActualWriteRight)
              )
              : [];
          const conferenceGroups =
            conferenceRead && hotel.departmentExists('C_AND_E')
              ? this.createConferenceLines(
                hasAdminWrite || (hasConferenceWriteDept && hasForecastWriteRight),
                hasAdminWrite || (hasConferenceWriteDept && hasActualWriteRight)
              )
              : [];
          return [].concat(hotelGroups, frontOfficeGroups, clubLoungeGroups, outletGroups, barGroups, roomsServiceGroups, conferenceGroups);
        }
      )
    );
  }

  hasAdminRight(right: Right) {
    return this.authService.hasAnyRoles([
      [Scope.REGION_ADMIN, right],
      [Scope.SCHEDULE_ADMIN, right],
    ]);
  }

  createHotelLines(allowForecastWrite, allowActualWrite, numberOfRooms) {
    return [
      {
        sectionLabel: 'Rooms',
        sectionIndex: 0,
        sectionType: 'HOTEL',
        lines: [
          {
            sectionType: 'HOTEL',
            sectionIndex: 0,
            sectionLabel: 'Rooms',
            metricLabel: 'Rooms',
            dataLabel: 'Hotel',
            dataKey: 'roomsAvailable',
            isInteger: true,
            days: [],
            allowActualWrite,
            allowForecastWrite,
            sectionClass: 'section-end',
            default: numberOfRooms,
          },
          {
            sectionType: 'HOTEL',
            sectionIndex: 0,
            sectionLabel: 'Rooms',
            metricLabel: 'Occupied',
            dataLabel: 'Rooms',
            isInteger: true,
            dataKey: 'roomsOccupied',
            days: [],
            allowActualWrite,
            allowForecastWrite,
          },
          {
            sectionType: 'HOTEL',
            sectionIndex: 0,
            sectionLabel: 'Rooms',
            metricLabel: '(%)',
            dataLabel: 'Occupancy',
            dataKey: 'occupancy',
            days: [],
            allowActualWrite: false,
            allowForecastWrite: false,
            exportExclude: true,
            average: true,
          },
          {
            sectionType: 'HOTEL',
            sectionIndex: 0,
            sectionLabel: 'Rooms',
            metricLabel: 'Rooms',
            dataLabel: 'Calculated Occupancy',
            dataKey: 'occupancyE',
            days: [],
            allowActualWrite: false,
            allowForecastWrite: false,
            exportExclude: true,
          },
          {
            sectionType: 'HOTEL',
            sectionIndex: 0,
            sectionLabel: 'Rooms',
            metricLabel: 'In-House',
            dataLabel: 'Guests',
            isInteger: true,
            dataKey: 'guests',
            days: [],
            allowActualWrite,
            allowForecastWrite,
            sectionClass: 'section-end',
          },
        ],
      },
    ] as IDailyDriverGroup[];
  }

  createFrontOfficeLines(allowForecastWrite, allowActualWrite, hotelRooms) {
    return [
      {
        sectionType: 'FRONTOFFICE',
        sectionIndex: 0,
        sectionLabel: 'Front Office',
        lines: [
          {
            sectionType: 'FRONTOFFICE',
            sectionIndex: 0,
            sectionLabel: 'Rooms',
            metricLabel: '',
            dataLabel: 'Departures (check-outs)',
            isInteger: true,
            dataKey: 'departures',
            days: [],
            min: 0,
            max: hotelRooms * 10,
            allowActualWrite,
            allowForecastWrite,
          },
          {
            sectionType: 'FRONTOFFICE',
            sectionIndex: 0,
            sectionLabel: 'Rooms',
            metricLabel: '',
            dataLabel: 'Arrivals (check-ins)',
            isInteger: true,
            dataKey: 'arrivals',
            days: [],
            min: 0,
            max: hotelRooms * 10,
            allowActualWrite,
            allowForecastWrite,
            sectionClass: 'section-end',
          },
        ],
      },
    ] as IDailyDriverGroup[];
  }

  createClubLoungeLines(allowForecastWrite, allowActualWrite, numberOfClubRooms): IDailyDriverGroup[] {
    return [
      {
        sectionType: 'CLUBLOUNGE',
        sectionIndex: 0,
        sectionLabel: 'Club Lounge',
        lines: [
          {
            sectionType: 'CLUBLOUNGE',
            sectionIndex: 0,
            sectionLabel: 'Club Lounge',
            metricLabel: '(check-outs)',
            dataLabel: 'Club Lounge Departures',
            dataKey: 'departures',
            days: [],
            min: 0,
            max: numberOfClubRooms * 10,
            allowActualWrite,
            allowForecastWrite,
          },
          {
            sectionType: 'CLUBLOUNGE',
            sectionIndex: 0,
            sectionLabel: 'Club Lounge',
            metricLabel: '(check-ins)',
            dataLabel: 'Club Lounge Arrivals',
            isInteger: true,
            min: 0,
            max: numberOfClubRooms * 10,
            dataKey: 'arrivals',
            days: [],
            allowActualWrite,
            allowForecastWrite,
          },
          {
            sectionType: 'CLUBLOUNGE',
            sectionIndex: 0,
            sectionLabel: 'Club Lounge',
            metricLabel: 'In-House',
            dataLabel: 'Club Lounge Guests',
            isInteger: true,
            dataKey: 'occupied',
            days: [],
            min: 0,
            max: numberOfClubRooms * 10,
            allowActualWrite,
            allowForecastWrite,
            sectionClass: 'section-end',
          },
        ],
      },
    ];
  }

  createRoomServiceLines(allowForecastWrite, allowActualWrite): IDailyDriverGroup[] {
    return [
      {
        sectionType: 'ROOMSERVICE',
        sectionIndex: 0,
        sectionLabel: 'Room Service',
        lines: [
          {
            sectionType: 'ROOMSERVICE',
            sectionIndex: 0,
            sectionLabel: 'Room Service',
            metricLabel: 'Customers',
            dataLabel: 'Breakfast',
            isInteger: true,
            dataKey: 'breakfast',
            days: [],
            min: 0,
            allowActualWrite,
            allowForecastWrite,
          },
          {
            sectionType: 'ROOMSERVICE',
            sectionIndex: 0,
            sectionLabel: 'Room Service',
            metricLabel: 'Customers',
            dataLabel: 'Lunch',
            isInteger: true,
            dataKey: 'lunch',
            days: [],
            min: 0,
            allowActualWrite,
            allowForecastWrite,
          },
          {
            sectionType: 'ROOMSERVICE',
            sectionIndex: 0,
            sectionLabel: 'Room Service',
            metricLabel: 'Customers',
            dataLabel: 'Dinner',
            isInteger: true,
            dataKey: 'dinner',
            days: [],
            min: 0,
            allowActualWrite,
            allowForecastWrite,
          },
          {
            sectionType: 'ROOMSERVICE',
            sectionIndex: 0,
            sectionLabel: 'Room Service',
            metricLabel: 'Customers',
            dataLabel: 'Late Night',
            isInteger: true,
            dataKey: 'lateNight',
            days: [],
            min: 0,
            allowActualWrite,
            allowForecastWrite,
            sectionClass: 'section-end',
          },
        ],
      },
    ];
  }

  createConferenceLines(allowForecastWrite, allowActualWrite): IDailyDriverGroup[] {
    return [
      {
        sectionType: 'CANDB',
        sectionIndex: 0,
        sectionLabel: 'C & B Events Operations',
        lines: [
          {
            sectionType: 'CANDB',
            sectionIndex: 0,
            sectionLabel: 'C & B Events Operations',
            metricLabel: 'Customers',
            dataLabel: 'Breakfast',
            isInteger: true,
            dataKey: 'breakfast',
            days: [],
            min: 0,
            allowActualWrite,
            allowForecastWrite,
          },
          {
            sectionType: 'CANDB',
            sectionIndex: 0,
            sectionLabel: 'C & B Events Operations',
            metricLabel: 'Customers',
            dataLabel: 'Lunch',
            isInteger: true,
            dataKey: 'lunch',
            days: [],
            min: 0,
            allowActualWrite,
            allowForecastWrite,
          },
          {
            sectionType: 'CANDB',
            sectionIndex: 0,
            sectionLabel: 'C & B Events Operations',
            metricLabel: 'Customers',
            dataLabel: 'Dinner',
            isInteger: true,
            dataKey: 'dinner',
            days: [],
            min: 0,
            allowActualWrite,
            allowForecastWrite,
          },
          {
            sectionType: 'CANDB',
            sectionIndex: 0,
            sectionLabel: 'C & B Events Operations',
            metricLabel: 'Customers',
            dataLabel: 'Coffee Break',
            isInteger: true,
            dataKey: 'coffeeBreak',
            days: [],
            min: 0,
            allowActualWrite,
            allowForecastWrite,
          },
          {
            sectionType: 'CANDB',
            sectionIndex: 0,
            sectionLabel: 'C & B Events Operations',
            metricLabel: '(for Conference Room Set-Up)',
            dataLabel: 'Number of Delegates',
            isInteger: true,
            dataKey: 'setup',
            days: [],
            min: 0,
            allowActualWrite,
            allowForecastWrite,
          },
          {
            sectionType: 'CANDB',
            sectionIndex: 0,
            sectionLabel: 'C & B Events Operations',
            metricLabel: '(for Conference Room Cleardown)',
            dataLabel: 'Number of Delegates',
            isInteger: true,
            dataKey: 'cleardown',
            days: [],
            min: 0,
            allowActualWrite,
            allowForecastWrite,
            sectionClass: 'section-end',
          },
        ],
      },
    ];
  }

  createBarLines(
    { am, pm, breakfast, lunch, dinner, late, openingDays, ...out },
    allowForecastWrite,
    allowActualWrite
  ): IDailyDriverGroup[] {
    const closedDays = new Set<number>([0, 1, 2, 3, 4, 5, 6]);
    const closedMonths = new Set<number>(out.fullYear ? [] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
    Object.keys(openingDays).forEach((key) => openingDays[key] && closedDays.delete(days[key]));
    if (!out.fullYear) {
      Object.keys(out).forEach((key) => typeof months[key] === 'number' && out[key] !== false && closedMonths.delete(months[key]));
    }
    const lines: DailyDriverLine[] = [];
    // first do the beverages
    if (typeof breakfast === 'boolean' ? breakfast : am) {
      lines.push({
        sectionType: 'BAR',
        sectionIndex: out.index,
        sectionLabel: out.name,
        metricLabel: '- Breakfast Revenue (AM)',
        dataLabel: 'Beverage',
        dataKey: 'beverageBreakfast',
        days: [],
        min: 0,
        allowActualWrite,
        allowForecastWrite,
        closedDays: closedDays.size > 0 ? closedDays : undefined,
        closedMonths: closedMonths.size > 0 ? closedMonths : undefined,
      });
    }
    if (typeof lunch === 'boolean' ? lunch : am) {
      lines.push({
        sectionType: 'BAR',
        sectionIndex: out.index,
        sectionLabel: out.name,
        metricLabel: '- Lunch Revenue (AM)',
        dataLabel: 'Beverage',
        dataKey: 'beverageLunch',
        days: [],
        min: 0,
        allowActualWrite,
        allowForecastWrite,
        closedDays: closedDays.size > 0 ? closedDays : undefined,
        closedMonths: closedMonths.size > 0 ? closedMonths : undefined,
      });
    }
    if (typeof dinner === 'boolean' ? dinner : pm) {
      lines.push({
        sectionType: 'BAR',
        sectionIndex: out.index,
        sectionLabel: out.name,
        metricLabel: '- Dinner Revenue (PM)',
        dataLabel: 'Beverage',
        dataKey: 'beverageDinner',
        days: [],
        min: 0,
        allowActualWrite,
        allowForecastWrite,
        closedDays: closedDays.size > 0 ? closedDays : undefined,
        closedMonths: closedMonths.size > 0 ? closedMonths : undefined,
      });
    }
    if (typeof late === 'boolean' ? late : pm) {
      lines.push({
        sectionType: 'BAR',
        sectionIndex: out.index,
        sectionLabel: out.name,
        metricLabel: '- Late Night Revenue (PM)',
        dataLabel: 'Beverage',
        dataKey: 'beverageLateNight',
        days: [],
        min: 0,
        allowActualWrite,
        allowForecastWrite,
        closedDays: closedDays.size > 0 ? closedDays : undefined,
        closedMonths: closedMonths.size > 0 ? closedMonths : undefined,
      });
    }
    // now add food
    if (typeof breakfast === 'boolean' ? breakfast : am) {
      lines.push({
        sectionType: 'BAR',
        sectionIndex: out.index,
        sectionLabel: out.name,
        metricLabel: '- Breakfast Revenue (AM)',
        dataLabel: 'Food',
        dataKey: 'foodBreakfast',
        days: [],
        min: 0,
        allowActualWrite,
        allowForecastWrite,
        closedDays: closedDays.size > 0 ? closedDays : undefined,
        closedMonths: closedMonths.size > 0 ? closedMonths : undefined,
      });
    }
    if (typeof lunch === 'boolean' ? lunch : am) {
      lines.push({
        sectionType: 'BAR',
        sectionIndex: out.index,
        sectionLabel: out.name,
        metricLabel: '- Lunch Revenue (AM)',
        dataLabel: 'Food',
        dataKey: 'foodLunch',
        days: [],
        min: 0,
        allowActualWrite,
        allowForecastWrite,
        closedDays: closedDays.size > 0 ? closedDays : undefined,
        closedMonths: closedMonths.size > 0 ? closedMonths : undefined,
      });
    }
    if (typeof dinner === 'boolean' ? dinner : pm) {
      lines.push({
        sectionType: 'BAR',
        sectionIndex: out.index,
        sectionLabel: out.name,
        metricLabel: '- Dinner Revenue (PM)',
        dataLabel: 'Food',
        dataKey: 'foodDinner',
        days: [],
        min: 0,
        allowActualWrite,
        allowForecastWrite,
        closedDays: closedDays.size > 0 ? closedDays : undefined,
        closedMonths: closedMonths.size > 0 ? closedMonths : undefined,
      });
    }
    if (typeof late === 'boolean' ? late : pm) {
      lines.push({
        sectionType: 'BAR',
        sectionIndex: out.index,
        sectionLabel: out.name,
        metricLabel: '- Late Night Revenue (PM)',
        dataLabel: 'Food',
        dataKey: 'foodLateNight',
        days: [],
        min: 0,
        allowActualWrite,
        allowForecastWrite,
        sectionClass: 'section-end',
        closedDays: closedDays.size > 0 ? closedDays : undefined,
        closedMonths: closedMonths.size > 0 ? closedMonths : undefined,
      });
    }
    return [
      {
        sectionType: 'BAR',
        sectionIndex: out.index,
        sectionLabel: out.name,
        lines: [...lines],
      },
    ];
  }

  createRestaurantLines(
    { breakfast, lunch, dinner, late, openingDays, ...out },
    allowForecastWrite,
    allowActualWrite
  ): IDailyDriverGroup[] {
    const closedDays = new Set<number>([0, 1, 2, 3, 4, 5, 6]);
    const closedMonths = new Set<number>(out.fullYear ? [] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
    Object.keys(openingDays).forEach((key) => openingDays[key] && closedDays.delete(days[key]));
    if (!out.fullYear) {
      Object.keys(out).forEach((key) => typeof months[key] === 'number' && out[key] !== false && closedMonths.delete(months[key]));
    }
    const lines: DailyDriverLine[] = [];
    if (breakfast) {
      lines.push({
        sectionType: 'RESTAURANT',
        sectionIndex: out.index,
        sectionLabel: out.name,
        metricLabel: 'Customers',
        dataLabel: 'Breakfast',
        isInteger: true,
        dataKey: 'foodBreakfast',
        days: [],
        allowActualWrite,
        allowForecastWrite,
        min: 0,
        closedDays: closedDays.size > 0 ? closedDays : undefined,
        closedMonths: closedMonths.size > 0 ? closedMonths : undefined,
      });
    }
    if (lunch) {
      lines.push({
        sectionType: 'RESTAURANT',
        sectionIndex: out.index,
        sectionLabel: out.name,
        metricLabel: 'Customers',
        dataLabel: 'Lunch',
        isInteger: true,
        dataKey: 'foodLunch',
        days: [],
        allowActualWrite,
        allowForecastWrite,
        min: 0,
        closedDays: closedDays.size > 0 ? closedDays : undefined,
        closedMonths: closedMonths.size > 0 ? closedMonths : undefined,
      });
    }
    if (dinner) {
      lines.push({
        sectionType: 'RESTAURANT',
        sectionIndex: out.index,
        sectionLabel: out.name,
        metricLabel: 'Customers',
        dataLabel: 'Dinner',
        isInteger: true,
        dataKey: 'foodDinner',
        days: [],
        allowActualWrite,
        allowForecastWrite,
        min: 0,
        sectionClass: 'section-end',
        closedDays: closedDays.size > 0 ? closedDays : undefined,
        closedMonths: closedMonths.size > 0 ? closedMonths : undefined,
      });
    }
    if (late) {
      lines.push({
        sectionType: 'RESTAURANT',
        sectionIndex: out.index,
        sectionLabel: out.name,
        metricLabel: 'Customers',
        dataLabel: 'Late',
        isInteger: true,
        dataKey: 'foodLateNight',
        days: [],
        allowActualWrite,
        allowForecastWrite,
        min: 0,
        sectionClass: 'section-end',
        closedDays: closedDays.size > 0 ? closedDays : undefined,
        closedMonths: closedMonths.size > 0 ? closedMonths : undefined,
      });
    }
    return [
      {
        sectionType: 'RESTAURANT',
        sectionIndex: out.index,
        sectionLabel: out.name,
        lines: [...lines],
      },
    ];
  }

  loadWOTNumbers() {
    this.log.info('in load WOT numbers');
    this.log.debug(this.activeMonthStart.toISOString());
    this.driverData
      .getHotelWOT(this.activeMonthStart.year(), this.activeMonthStart.month() + 1)
      .pipe(first())
      .subscribe((data) => {
        this.log.debug('loadWotNumbers - getHotelWOT');
        if (!data) {
          this.setWOT('HOTEL', 0, 'roomsOccupied', 'N/A');
          this.setWOT('HOTEL', 0, 'roomsAvailable', 'N/A');
          this.setWOT('FRONTOFFICE', 0, 'departures', 'N/A');
          this.setWOT('FRONTOFFICE', 0, 'arrivals', 'N/A');
          this.setWOT('CLUBLOUNGE', 0, 'departures', 'N/A');
          this.setWOT('CLUBLOUNGE', 0, 'arrivals', 'N/A');
          this.setWOT('CLUBLOUNGE', 0, 'occupied', 'N/A');
          this.setWOT('ROOMSERVICE', 0, 'breakfast', 'N/A');
          this.setWOT('ROOMSERVICE', 0, 'lunch', 'N/A');
          this.setWOT('ROOMSERVICE', 0, 'dinner', 'N/A');
          this.setWOT('ROOMSERVICE', 0, 'lateNight', 'N/A');
          this.setWOT('CANDB', 0, 'breakfast', 'N/A');
          this.setWOT('CANDB', 0, 'lunch', 'N/A');
          this.setWOT('CANDB', 0, 'dinner', 'N/A');
          this.setWOT('CANDB', 0, 'coffeeBreak', 'N/A');
        } else {
          this.setWOT('HOTEL', 0, 'roomsOccupied', data.roomsOccupied);
          this.setWOT('HOTEL', 0, 'roomsAvailable', data.roomsAvailable);
          this.setWOT('HOTEL', 0, 'occupancy', data.roomsAvailable ? ((data.roomsOccupied || 0) / data.roomsAvailable) * 100 : 0);
          this.setWOT('HOTEL', 0, 'guests', 'N/A');
          this.setWOT('FRONTOFFICE', 0, 'departures', data.departures);
          this.setWOT('FRONTOFFICE', 0, 'arrivals', data.arrivals);
          this.setWOT('CLUBLOUNGE', 0, 'departures', data.clubLounge.departures);
          this.setWOT('CLUBLOUNGE', 0, 'arrivals', data.clubLounge.arrivals);
          this.setWOT('CLUBLOUNGE', 0, 'occupied', data.clubLounge.roomsOccupied);
          this.setWOT('ROOMSERVICE', 0, 'breakfast', data.roomService.breakfast);
          this.setWOT('ROOMSERVICE', 0, 'lunch', data.roomService.lunch);
          this.setWOT('ROOMSERVICE', 0, 'dinner', data.roomService.dinner);
          this.setWOT('ROOMSERVICE', 0, 'lateNight', data.roomService.lateNight);
          this.setWOT('CANDB', 0, 'breakfast', data.conferences.breakfast);
          this.setWOT('CANDB', 0, 'lunch', data.conferences.lunch);
          this.setWOT('CANDB', 0, 'dinner', data.conferences.dinner);
          this.setWOT('CANDB', 0, 'coffeeBreak', data.conferences.coffeeBreak);
        }
      });
    this.driverData
      .getOutletWOT(this.activeMonthStart.year(), this.activeMonthStart.month() + 1)
      .pipe(first())
      .subscribe((data) => {
        if (!data) {
          return;
        }
        data.forEach((row) => {
          this.setWOT(row.outletType, row.outletIndex, 'foodBreakfast', row.foodBreakfast);
          this.setWOT(row.outletType, row.outletIndex, 'foodLunch', row.foodLunch);
          this.setWOT(row.outletType, row.outletIndex, 'foodDinner', row.foodDinner);
          this.setWOT(row.outletType, row.outletIndex, 'foodLateNight', row.foodLateNight);
          this.setWOT(row.outletType, row.outletIndex, 'beverageBreakfast', row.beverageBreakfast);
          this.setWOT(row.outletType, row.outletIndex, 'beverageLunch', row.beverageLunch);
          this.setWOT(row.outletType, row.outletIndex, 'beverageDinner', row.beverageDinner);
          this.setWOT(row.outletType, row.outletIndex, 'beverageLateNight', row.beverageLateNight);
        });
      });
  }

  setWOT(type, index, key, value?: number | 'N/A') {
    this.data.forEach((group) => {
      group.lines.forEach((i) => {
        if (i.sectionType != type || i.sectionIndex != index || i.dataKey != key) return; //not a match
        if (typeof value === 'string') {
          i.WOTTotal = null;
          i.WOTTotalString = value;
        } else {
          i.WOTTotal = value;
        }
      });
    });
  }

  loadDaysForEachLine() {
    const blank = {
      forecast: 0,
      fcDirty: false,
      fcLoaded: false,
      actual: 0,
      aDirty: false,
      aLoaded: false,
    };
    this.loadQueue = [];
    const totalLines = this.data.reduce((c, group) => c + group.lines.length, 0);

    let tabIndex = 1000;
    this.data.forEach((group) => {
      group.lines.forEach((l) => {
        this.queueLoad(l);
        tabIndex++;
        l.days = this.dateRange.map((d, i) => {
          return { ...blank, tabIndex: i * totalLines + tabIndex };
        }); //make a list of blanks and loading requirements
        l.aTotal = l.fTotal = l.aWeekTotal = l.fWeekTotal = 0;
      });
    });
    this.loadQueue.forEach((qi) => this.loadDaysData(qi));
  }

  queueLoad(line: DailyDriverLine) {
    const item = this.loadQueue.find((q) => q.sectionType == line.sectionType && q.sectionIndex == line.sectionIndex);
    if (!item) {
      this.loadQueue.push({
        sectionType: line.sectionType,
        sectionIndex: line.sectionIndex,
        default: line.dataKey ? { [line.dataKey]: line.default } : {},
      });
    } else {
      item.default = {
        ...item.default,
        [line.dataKey]: line.default,
      };
    }
  }

  loadDaysData(qi: { sectionType; sectionIndex; default?: Record<string, any> }) {
    if (this.hasForecastRead) {
      this.driverData
        .getDriverForecastDays(qi.sectionType, qi.sectionIndex, this.dateRange[0], this.dateRange[this.dateRange.length - 1])
        .pipe(first())
        .subscribe((daysData) => {
          this.data.forEach((group) => {
            group.lines.forEach((i) => {
              if (i.sectionType !== qi.sectionType || i.sectionIndex != qi.sectionIndex) return; //not a match
              //now fill in data for each day
              this.dateRange.forEach((d, dn) => {
                let sec = i.days[dn];
                if (i.closedMonths && i.closedMonths.has(d.getMonth())) {
                  sec.closed = true;
                } else if (i.closedDays && i.closedDays.has(d.getDay())) {
                  sec.closed = true;
                }
                //find matching date from the list
                let newF = daysData.find(
                  (dayD) => d.getFullYear() == dayD.year && d.getMonth() + 1 == dayD.month && d.getDate() == dayD.day
                );
                sec.fcDirty = false;
                sec.fcLoaded = true;
                if (newF) sec.forecast = newF[i.dataKey];
                else if (qi.default[i.dataKey]) {
                  //didn't get it but we do have a default
                  sec.forecast = qi.default[i.dataKey];
                }
              });
              this.calculateForecastLineTotal(i);
            });
            this.calculateExpectedOccupancy();
          });
        });
    }
    if (this.hasActualRead) {
      this.driverData
        .getDriverActualDays(qi.sectionType, qi.sectionIndex, this.dateRange[0], this.dateRange[this.dateRange.length - 1])
        .pipe(first())
        .subscribe((daysData) => {
          this.data.forEach((group) => {
            group.lines.forEach((i) => {
              if (i.sectionType !== qi.sectionType || i.sectionIndex != qi.sectionIndex) return; //not a match
              //now fill in data for each day
              this.dateRange.forEach((d, dn) => {
                let sec = i.days[dn];
                //find matching data from the list
                let newA = daysData.find(
                  (dayD) => d.getFullYear() == dayD.year && d.getMonth() + 1 == dayD.month && d.getDate() == dayD.day
                );
                sec.aDirty = false;
                sec.aLoaded = true;
                if (newA) sec.actual = newA[i.dataKey];
                else if (qi.default[i.dataKey])
                  //didn't get it but we do have a default
                  sec.actual = qi.default[i.dataKey];
              });
              this.calculateActualLineTotal(i);
            });
            this.calculateExpectedOccupancy();
          });
        });
    }
  }

  findLine(sectionType: String, dataKey: String): DailyDriverLine {
    const section = this.data.find((v) => v.sectionType == sectionType);
    return section?.lines.find((v) => v.dataKey == dataKey);
  }

  calculateExpectedOccupancy() {
    //need to calculate the forecast for each day from previous days
    const line = this.findLine('HOTEL', 'occupancyE');
    if (!line) {
      return;
    }
    for (const i of this.displayRange) {
      if (!line.days[i]) continue; //no day set up
      let j = i == 0 ? i : i - 1;
      line.days[i].forecast =
        (this.findLine('HOTEL', 'roomsOccupied')?.days[j]?.forecast || 0) +
        (this.findLine('FRONTOFFICE', 'arrivals')?.days[i]?.forecast || 0) -
        (this.findLine('FRONTOFFICE', 'departures')?.days[i]?.forecast || 0);
      line.days[i].actual =
        (this.findLine('HOTEL', 'roomsOccupied')?.days[j]?.actual || 0) +
        (this.findLine('FRONTOFFICE', 'arrivals')?.days[i]?.actual || 0) -
        (this.findLine('FRONTOFFICE', 'departures')?.days[i]?.actual || 0);
    }
  }

  calculateForecastLineTotal(line: DailyDriverLine) {
    let fTotal = 0;
    let fWeekTotal = 0;
    let totalDays = 0;
    let totalDaysClosed = 0;
    let totalWeekDaysClosed = 0;
    for (let i = this.activeMonthIndexStart; i <= this.activeMonthIndexEnd; i++) {
      if (!line.days[i]) continue;
      if (line.days[i]?.closed) {
        totalDaysClosed++;
      } else if (line.days[i]?.forecast !== null) {
        totalDays++;
        fTotal += line.days[i]?.forecast;
      }
    }
    let totalWeekDays = 0;
    for (const i of this.displayRange) {
      if (line.days[i]?.closed) {
        totalWeekDaysClosed++;
      } else if (line.days[i]?.forecast !== null) {
        fWeekTotal += line.days[i]?.forecast;
        totalWeekDays++;
      }
    }
    if (line.average) {
      line.fTotal = totalDays > 0 ? fTotal / totalDays : null;
      line.fWeekTotal = totalWeekDays + totalWeekDaysClosed === 7 ? fWeekTotal / totalWeekDays : null;
    } else {
      line.fTotal = totalDays > 0 ? fTotal : null;
      line.fWeekTotal = totalWeekDays + totalWeekDaysClosed === 7 ? fWeekTotal : null;
    }

    line.fTotalPartial = totalDays + totalDaysClosed !== this.activeMonthIndexEnd - this.activeMonthIndexStart + 1;
  }

  calculateActualLineTotal(line: DailyDriverLine) {
    let aTotal = 0;
    let aWeekTotal = 0;
    let totalDays = 0;
    for (let i = this.activeMonthIndexStart; i <= this.activeMonthIndexEnd; i++) {
      if (!line.days[i]) continue;
      if (line.days[i]?.forecast !== null) {
        totalDays++;
        aTotal += line.days[i]?.actual;
      }
    }
    let count = 0;
    for (const i of this.displayRange) {
      if (line.days[i]?.actual !== null) {
        count++;
        aWeekTotal += line.days[i]?.actual;
      }
    }
    if (line.average) {
      line.aTotal = totalDays > 0 ? aTotal / totalDays : null;
      line.aWeekTotal = count === 7 ? aWeekTotal / count : null;
    } else {
      line.aTotal = totalDays > 0 ? aTotal : null;
      line.aWeekTotal = count === 7 ? aWeekTotal : null;
    }
    line.aTotalPartial = totalDays !== this.activeMonthIndexEnd - this.activeMonthIndexStart + 1;
  }

  dayDirty(row: DailyDriverLine, day: number, forecast: boolean, actual: boolean, useAllMain = false) {
    if (forecast) row.days[day].fcDirty = true;
    if (actual) row.days[day].aDirty = true;
    this.log.debug({ ...row.days[day], day, type: forecast ? 'forecast' : 'actual' });
    if (forecast) this.calculateForecastLineTotal(row);
    if (actual) this.calculateActualLineTotal(row);
    this.calculateExpectedOccupancy();
    this.queueUpdate(row, day, forecast, actual, useAllMain);
  }

  queueUpdate(row: DailyDriverLine, day: number, forecast: boolean, actual: boolean, useAllMain: boolean) {
    if (useAllMain && ['BAR', 'RESTAURANT'].includes(row.sectionType)) useAllMain = false; //not for outlets
    let dPrefix = useAllMain ? row.sectionType : '';
    let secType = useAllMain ? 'ALLMAIN' : row.sectionType;
    //check the current queue for a matching item
    const item = this.updateQueue.find((q) => q.sectionType == secType && q.sectionIndex == row.sectionIndex && q.day == day);
    if (item) {
      item.lastChange = +new Date();
      item.newSectionValue[dPrefix + row.dataKey] = { value: forecast ? row.days[day].forecast : row.days[day].actual, changed: true };
      item.forecast ||= forecast;
      item.actual ||= actual;
    } else {
      const newValue: Record<string, { changed?: boolean; value: number }> = this.data.reduce((prev, group) => {
        return group.lines
          .filter(
            (l) =>
              (l.sectionType === row.sectionType && l.sectionIndex === row.sectionIndex) ||
              (useAllMain && !['BAR', 'RESTAURANT'].includes(l.sectionType))
          )
          .reduce((p, line) => {
            p[(useAllMain ? line.sectionType : '') + line.dataKey] = { value: forecast ? line.days[day].forecast : line.days[day].actual };
            return p;
          }, prev);
      }, {});
      newValue[dPrefix + row.dataKey].changed = true;
      this.updateQueue.push({
        sectionType: secType,
        sectionIndex: row.sectionIndex,
        day: day,
        lastChange: +new Date(),
        forecast: forecast,
        actual: actual,
        label: `${row.dataLabel} ${row.metricLabel}`,
        newSectionValue: newValue,
      });
    }
  }

  clearUpdateMessage = () => {
    this.messageService.clear('bottom-right');
    this.showingUpdate = false;
  };

  isUpdating() {
    return this.updateQueue?.length > 0 || this.activeUpdates > 0;
  }

  processUpdates(saveAll: boolean) {
    if (this.importingData) {
      return;
    }
    //make a list of items to save now
    const now = +new Date();
    const currentQueue = [...this.updateQueue];
    if (currentQueue.length > 0) {
      if (!this.showingUpdate) {
        this.messageService.add({
          id: 'update_message',
          key: 'bottom-right',
          severity: 'info',
          summary: 'Updating drivers...',
          icon: 'pi-spin pi-spinner',
          sticky: true,
        });
      }
    } else {
      if (!this.showingUpdate) {
        this.clearUpdateMessage();
      }
    }
    this.updateQueue = [];
    const { nowQueue: updateNow, laterQueue: updateLater } = currentQueue.reduce(
      ({ nowQueue, laterQueue }, i) => {
        if (saveAll || (nowQueue.length < 4 && now - i.lastChange > 2000)) {
          nowQueue.push(i);
        } else {
          laterQueue.push(i);
        }
        return { nowQueue, laterQueue };
      },
      { nowQueue: [] as IQueueEntry[], laterQueue: [] as IQueueEntry[] }
    );
    this.updateQueue = updateLater;

    if (this.requestRefresh && !updateNow.length && !updateLater.length) {
      //if we have a full reload request and we have completed all updates
      this.requestRefresh = false;
      setTimeout(() => {
        this.firstDay = dayjs('1900-01-01'); //clear current data by resetting context
        this.dateChange();
      }, 1500);
      return;
    }
    if (!updateNow.length) return; //nothing to update

    const updateAndRetry = (obs: Observable<any>, obsType: 'actual' | 'forecast') =>
      interval(this.UPDATE_INTERVAL_MS * Math.random()).pipe(
        first(),
        tap(
          () => this.activeUpdates++,
          () => this.activeUpdates++
        ),
        mergeMap(() => obs),
        first(),
        catchError(() => {
          const waitTime = this.UPDATE_INTERVAL_MS / 2 + this.UPDATE_INTERVAL_MS * Math.random();
          this.log.info(`Retrying ${obsType} driver update waiting ${waitTime}ms...`);
          this.messageService.clear('bottom-right');
          this.messageService.add({
            id: 'update_message',
            key: 'bottom-right',
            severity: 'warn',
            summary: `Retrying ${obsType} driver update...`,
            icon: 'pi-spin pi-spinner',
            life: 3000 + waitTime,
          });
          // wait a little bit and then retry
          return interval(waitTime).pipe(
            first(),
            mergeMap(() => obs)
          );
        }),
        first(),
        tap(
          () => this.activeUpdates--,
          () => this.activeUpdates--
        )
      );

    //do the saves
    updateNow.forEach((i) => {
      const newSectionValue = Object.keys(i.newSectionValue).reduce((prev, key) => {
        prev[key] = i.newSectionValue[key].value;
        return prev;
      }, {});
      const index = this.data.findIndex((entry) => entry.sectionIndex === i.sectionIndex && entry.sectionType === i.sectionType);
      const group: IDailyDriverGroup = index >= 0 ? this.data[index] : undefined;
      const reloadGroupFromServer = group
        ? () => {
          group.lines.forEach((line) => {
            this.queueLoad(line);
          });
        }
        : () => undefined;
      //save the values
      if (i.forecast) {
        this.log.info(
          'updating data forecast ' + i.sectionType + ' sec index:' + i.sectionIndex + ' ' + this.dateRange[i.day],
          newSectionValue
        );
        const date = this.dateRange[i.day];
        const { sectionType, sectionIndex } = i || {};
        updateAndRetry(this.driverData.setDriverForecastDay(sectionType, sectionIndex, date, newSectionValue), 'forecast').subscribe(
          (data) => {
            this.clearUpdateMessage();
            if (!data) {
              const { newSectionValue: _, ...queueEntry } = i || {};
              this.sentryService.sendMessage('No data received after setting driver forecast', 'warning', {
                sectionType,
                sectionIndex,
                newSectionValue,
                date,
                hotel: this.contextService.getCurrentBasicHotel(),
                saveAll,
                queueEntry,
              });
              reloadGroupFromServer();
              return;
            }
            if (!group) {
              return;
            }
            const lines = group.lines.map((line) => {
              // not sure why line.days[i.day] would be empty but picked up this error in sentry
              // https://sudoorgza.sentry.io/issues/5053563788/?project=5764862
              if (line.days[i.day]) {
                line.days[i.day].forecast = data[line.dataKey];
                if (i.newSectionValue[line.dataKey].changed) {
                  line.days[i.day].fcDirty = false;
                }
              }
              return line;
            });
            group.lines = lines;
            group.lines.forEach((line) => this.calculateForecastLineTotal(line));
            this.calculateExpectedOccupancy();
            this.log.debug(`updated forecast ${i.label} for ${i.day}`, i);
            this.data = [...this.data.slice(0, index), group, ...this.data.slice(index + 1)];
          },
          (error) => {
            this.clearUpdateMessage();
            if (group) {
              group.lines = group.lines.map((line) => {
                line.days[i.day].fcDirty = false;
                return line;
              });
            }
            const suffix = this.dateRange[i.day] ? ` for ${dayjs(this.dateRange[i.day]).format('MMM d')}` : '';
            this.sentryService.showAndSendError(
              error,
              'Internal error - drivers',
              `Unable to update driver forecast value ${i.label}${suffix},${i.day} please reload.`,
              {
                i,
                hotel: this.contextService?.getCurrentHotelId(),
                suffix,
                dateRange: this.dateRange[i.day],
                newSectionValue,
                errorMessage: error?.message,
                errorName: error?.name,
              }
            );
            reloadGroupFromServer();
          }
        );
      }
      if (i.actual) {
        this.log.info('updating data actual' + i.sectionType + ' index:' + i.sectionIndex + ' ' + this.dateRange[i.day], newSectionValue);
        const date = this.dateRange[i.day];
        const { sectionType, sectionIndex } = i || {};
        updateAndRetry(this.driverData.setDriverActualDay(sectionType, sectionIndex, date, newSectionValue), 'actual').subscribe(
          (data) => {
            this.clearUpdateMessage();
            if (!data) {
              const { newSectionValue, ...queueEntry } = i || {};
              this.sentryService.sendMessage('No data received after setting driver actual', 'warning', {
                date,
                sectionIndex,
                sectionType,
                newSectionValue,
                hotel: this.contextService.getCurrentBasicHotel(),
                saveAll,
                queueEntry,
              });
              reloadGroupFromServer();
              return;
            }
            const index = this.data.findIndex((entry) => entry.sectionIndex === i.sectionIndex && entry.sectionType === i.sectionType);
            if (index < 0) {
              return;
            }
            const group = this.data[index];
            const lines = group.lines.map((line) => {
              // work around for https://sudoorgza.sentry.io/issues/5126066192/?project=5764862
              if (line.days[i.day]) {
                line.days[i.day].actual = data[line.dataKey];
                if (i.newSectionValue[line.dataKey].changed) {
                  line.days[i.day].aDirty = false;
                }
              }
              return line;
            });
            group.lines = lines;
            group.lines.forEach((line) => this.calculateActualLineTotal(line));
            this.calculateExpectedOccupancy();
            this.log.debug(`updating actual ${i.label} for ${i.day}`, i);
            this.data = [...this.data.slice(0, index), group, ...this.data.slice(index + 1)];
          },
          (error) => {
            this.log.error('error setting driver', error);
            this.clearUpdateMessage();
            const index = this.data.findIndex((entry) => entry.sectionIndex === i.sectionIndex && entry.sectionType === i.sectionType);
            if (index >= 0) {
              const group = this.data[index];
              group.lines = group.lines.map((line) => {
                line.days[i.day].aDirty = false;
                return line;
              });
            }

            const suffix = this.displayRange[i.day] ? ` for ${dayjs(this.displayRange[i.day]).format('MMM d')}` : '';
            this.sentryService.showAndSendError(
              error,
              'Internal error - drivers',
              `Unable to update driver actual value ${i.label}${suffix}, please reload.`,
              { i, hotelId: this.contextService?.getCurrentHotelId() }
            );
            reloadGroupFromServer();
          }
        );
      }
    });
  }

  public export(fullMonth: boolean) {
    this.contextService
      .getCurrentBasicHotel$()
      .pipe(first())
      .subscribe((hotel) => {
        if (!hotel || !hotel.id) {
          this.ui.acknowledgeError(`No hotel selected or unable to retrieve hotel configuration.`);
          return;
        }

        this.xls.startSheet();
        //output title
        if (fullMonth) {
          this.xls.addTitleRow(
            'Daily Activity Drivers for month Starting of ' +
            this.activeMonthStart.format('DD/MM/YYYY') +
            ' for ' +
            hotel.name +
            ' - ' +
            hotel.holidexCode,
            'FFFFFF'
          );
          this.xls.addRow([' ', this.activeMonthStart.format('DD/MM/YYYY'), hotel.id, this.showCols, 'month']);
        } else {
          this.xls.addTitleRow(
            'Daily Activity Drivers for week of ' +
            dayjs(this.weekStart).format('DD/MM/YYYY') +
            ' for ' +
            hotel.name +
            ' - ' +
            hotel.holidexCode,
            'FFFFFF'
          );
          this.xls.addRow([' ', dayjs(this.weekStart).format('DD/MM/YYYY'), hotel.id, this.showCols, 'week']);
        }
        //figure out the date range
        let exportRange: number[];
        if (fullMonth)
          //generate an array of indees for this month
          exportRange = Array(this.activeMonthIndexEnd - this.activeMonthIndexStart + 1)
            .fill(0)
            .map((_, i) => i + this.activeMonthIndexStart);
        else exportRange = [...this.displayRange];

        //out header rows
        let head1 = ['Drivers', '', '', ''];
        let head2 = ['Label', 'Section', 'dataKey', 'index'];
        exportRange.forEach((di) => {
          head1.push(dayjs(this.dateRange[di]).format('DD/MM/YYYY'));
          if (this.showCols === ColCode.FORECAST_VS_ACTUAL) head1.push(dayjs(this.dateRange[di]).format('DD/MM/YYYY'));
          if (this.showCols === ColCode.FORECAST) head2.push('Fcst');
          if (this.showCols === ColCode.ACTUAL) head2.push('Act');
        });
        this.xls.addRow(head1);
        this.xls.addRow(head2);
        //Group headers
        this.data.forEach((gd) => {
          this.xls.addTitleRow(gd.sectionLabel, 'a1c6c8');
          //data lines
          gd.lines.forEach((l) => {
            if (l.exportExclude) return;
            let line: any[] = [l.dataLabel + ' ' + l.metricLabel, l.sectionType, l.dataKey, l.sectionIndex];
            exportRange.forEach((di) => {
              if (this.showCols === ColCode.FORECAST) line.push(l.days[di].closed ? 'closed' : l.days[di].forecast);
              if (this.showCols === ColCode.ACTUAL) line.push(l.days[di].closed ? 'closed' : l.days[di].actual);
            });
            this.xls.addRow(line);
          });
        });
        if (this.showCols != ColCode.FORECAST_VS_ACTUAL) this.xls.setColumnWidths([30, 10, 10, 10, 10, 10, 10, 10, 10]);
        else this.xls.setColumnWidths([30, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5]);
        //Hide some columns
        this.xls.hideColumn(2);
        this.xls.hideColumn(3);
        this.xls.hideColumn(4);

        //build file name
        let fp = ['drivers'];
        if (this.showCols === ColCode.FORECAST) fp.push('forecasts');
        if (this.showCols === ColCode.ACTUAL) fp.push('actuals');
        fp.push(this.contextService.getCurrentBasicHotel().holidexCode);
        if (fullMonth) fp.push('month', this.activeMonthStart.format('DD_MM_YYYY'));
        else fp.push('week', dayjs(this.weekStart).format('DD_MM_YYYY'));
        let fn = fp.join('-') + '.xlsx';
        //close
        this.xls.outputSheet(fn);
      });
  }

  async uploader(event) {
    this.ui.info('Processing import file...');
    await this.xls.openFile(event.files[0]);
    this.uploadControl.clear();

    if (this.xls.isLoaded()) {
      if (!this.validateSheet()) {
        this.ui.error('Sheet import failed!', 'The sheet content is not correct');
        return; //not right at all.
      }
      this.importSheetValues();
    } else {
      this.ui.error('File import failed!', 'Unable to open the file');
    }
  }

  findSheetLine = (sectionType, dataKey, sectionIndex) => {
    for (let i = 4; i < this.xls.numRows() + 1; i += 1)
      if (sectionType == this.xls.getCellVal(2, i) && dataKey == this.xls.getCellVal(3, i) && sectionIndex == +this.xls.getCellVal(4, i))
        return i;
    return 0;
  };

  validateSheet() {
    let sDate = this.xls.getCellVal(2, 2);
    let hotelId = +this.xls.getCellVal(3, 2);
    let type = +this.xls.getCellVal(4, 2);
    let dateType = this.xls.getCellVal(5, 2);
    let result = true;
    if (hotelId != this.contextService.getCurrentBasicHotel().id) {
      this.ui.error('Sheet content mismatched', 'The sheet imported was not intended for this hotel');
      result = false;
    }
    if (dateType != 'week' && dateType != 'month') {
      this.ui.error('Sheet content not current', 'The sheet imported was exported from this tool recently');
      result = false;
    }

    if (dateType == 'week' && sDate != dayjs(this.weekStart).format('DD/MM/YYYY')) {
      this.ui.error('Sheet content mismatched with screen', 'The sheet imported was not for this date range');
      result = false;
    }
    if (dateType == 'month' && sDate != this.activeMonthStart.format('DD/MM/YYYY')) {
      this.ui.error('Sheet content mismatched with screen', 'The sheet imported was not for this month range');
      result = false;
    }
    if (Number(type) != this.showCols) {
      const selectedLabel = this.showColCodes.find((val) => val.code === this.showCols)?.label || 'Unknown';
      const suppliedLabel = this.showColCodes.find((val) => val.code === type)?.label || 'Unknown';
      this.ui.error(
        'Sheet content mismatched with screen',
        `The sheet imported contains ${suppliedLabel} values instead of ${selectedLabel} values`
      );
      result = false;
    }
    let importRange: number[];
    if (dateType == 'month')
      //generate an array of indees for this month
      importRange = Array(this.activeMonthIndexEnd - this.activeMonthIndexStart + 1)
        .fill(0)
        .map((_, i) => i + this.activeMonthIndexStart);
    else importRange = [...this.displayRange];

    const integerErrors = new Set<string>();
    const valueErrors = new Set<string>();
    //scroll through the content
    this.data.forEach((gd) => {
      //data lines
      gd.lines.forEach((l) => {
        let sheetLine = this.findSheetLine(l.sectionType, l.dataKey, l.sectionIndex);
        if (sheetLine) {
          //we do have such a line
          importRange.forEach((di, pos) => {
            let forecast: any = 'XXX';
            let actual: any = 'XXX';

            if (l.days[di].closed) return;
            if (this.showCols === ColCode.FORECAST) forecast = +this.xls.getCellVal(5 + pos, sheetLine);
            if (this.showCols === ColCode.ACTUAL) actual = +this.xls.getCellVal(5 + pos, sheetLine);
            if (this.showCols === ColCode.FORECAST_VS_ACTUAL) {
              forecast = +this.xls.getCellVal(5 + pos * 2, sheetLine);
              actual = +this.xls.getCellVal(6 + pos * 2, sheetLine);
            }

            if (forecast !== 'XXX') {
              if (l.isInteger && !Number.isInteger(Number(forecast))) {
                integerErrors.add(`${l.sectionLabel}: ${l.dataLabel} ${l.metricLabel}`);
              }
              if (Number(forecast) < 0) {
                valueErrors.add(`${l.sectionLabel}: ${l.dataLabel} ${l.metricLabel}`);
              }
            }
            if (actual !== 'XXX') {
              if (l.isInteger && !Number.isInteger(Number(actual))) {
                integerErrors.add(`${l.sectionLabel}: ${l.dataLabel} ${l.metricLabel}`);
              }
              if (Number(actual) < 0) {
                valueErrors.add(`${l.sectionLabel}: ${l.dataLabel} ${l.metricLabel}`);
              }
            }
          });
        }
      });
    });
    if (integerErrors.size > 0 || valueErrors.size > 0) {
      let message = '';
      if (integerErrors.size > 0) {
        message += `The following values need to be integers: ${Array.from(integerErrors.keys())
          .map((label) => `"${label}"`)
          .join(', ')}`;
      }
      if (valueErrors.size > 0) {
        if (message.length) {
          message += '\n';
        }
        message += `The following values cannot be negative:\n ${Array.from(valueErrors.keys())
          .map((label) => `"${label}"`)
          .join(', ')}`;
      }
      this.ui.error('Sheet has invalid values', message);
      result = false;
    }
    return result;
  }

  importSheetValues() {
    // store the old values so if something goes wrong we can revert
    const data = [...this.data];
    // make sure that any changes in the queue have been process before we start the bulk update
    this.processUpdates(true);
    try {
      this.importingData = true;
      let count = 0;
      this.requestRefresh = true;

      let dateType = this.xls.getCellVal(5, 2);
      let importRange: number[];
      if (dateType == 'month')
        //generate an array of indees for this month
        importRange = Array(this.activeMonthIndexEnd - this.activeMonthIndexStart + 1)
          .fill(0)
          .map((_, i) => i + this.activeMonthIndexStart);
      else importRange = [...this.displayRange];

      const dirtyDays = [] as Array<{ row: DailyDriverLine; day: number; forecast: boolean; actual: boolean }>;
      const newData = this.data.map(({ lines, ...gd }) => ({
        ...gd,
        lines: lines.map((line) => {
          const sheetLine = this.findSheetLine(line.sectionType, line.dataKey, line.sectionIndex);
          if (sheetLine) {
            //we do have such a line
            const l = cloneDeep(line);
            importRange.forEach((di, pos) => {
              let forecast: any = 'XXX';
              let actual: any = 'XXX';

              if (l.days[di].closed) return;
              if (this.showCols === ColCode.FORECAST) forecast = +this.xls.getCellVal(5 + pos, sheetLine);
              if (this.showCols === ColCode.ACTUAL) actual = +this.xls.getCellVal(5 + pos, sheetLine);
              if (this.showCols === ColCode.FORECAST_VS_ACTUAL) {
                forecast = +this.xls.getCellVal(5 + pos * 2, sheetLine);
                actual = +this.xls.getCellVal(6 + pos * 2, sheetLine);
              }
              const updateForecast =
                forecast != 'XXX' &&
                l.days[di].forecast !== forecast &&
                l.allowForecastWrite &&
                (this.allowSuperWrite || this.canEditDayFC[di]);
              const updateActual =
                actual != 'XXX' && l.days[di].actual !== actual && l.allowActualWrite && (this.allowSuperWrite || this.canEditDayAct[di]);
              if (updateForecast) l.days[di].forecast = forecast;
              if (updateActual) l.days[di].actual = actual;
              if (updateForecast || updateActual) {
                dirtyDays.push({ row: l, day: di, forecast: updateForecast, actual: updateActual });
                count += 1;
              }
            });
            return l;
          } else {
            return line;
          }
        }),
      }));
      // we are doing a bulk update so we clear out all the old data and replace it with the imported new data
      this.data = [...newData];
      dirtyDays.forEach(({ row, day, forecast, actual }) => this.dayDirty(row, day, forecast, actual, true));

      this.ui.info('Data Imported', count + ' value(s) updated.');
      this.importingData = false;
      this.processUpdates(false);
    } catch (err) {
      this.importingData = false;
      this.data = data;
      this.sentryService.sendMessage('Error importing driver data', 'warning', { error: err });
    }
  }

  regenReports() {
    this.isLoading = true;
    this.messageService.add({
      id: 'update_message',
      key: 'bottom-right',
      severity: 'info',
      summary: 'Requesting refresh',
      icon: 'pi-spin pi-spinner',
      sticky: true,
    });

    this.driverData
      .requestReportRefresh(
        this.dateRange[0],
        this.dateRange[this.dateRange.length - 1],
        this.showCols === ColCode.FORECAST ? 'FORECAST' : this.showCols === ColCode.ACTUAL ? 'ACTUAL' : 'ALL'
      )
      .pipe(first())
      .subscribe(
        () => {
          this.clearUpdateMessage();
          this.messageService.add({
            id: 'update_message',
            key: 'bottom-right',
            severity: 'success',
            summary: 'Refresh requested',
            icon: 'pi-spin pi-spinner',
            life: 5000,
            sticky: false,
          });
          this.isLoading = false;
        },
        (refreshError) => {
          this.clearUpdateMessage();
          this.sentryService.showAndSendError(refreshError, 'Internal error - drivers', 'Unable to request refresh.');
          this.isLoading = false;
        }
      );
  }
}
